@use '_reset.scss';
@use '_hacks.scss';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Prociono;
  src: url('../fonts/Prociono-Regular.eot');
  src: url('../fonts/Prociono-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/Prociono-Regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/Prociono-Regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/Prociono-Regular.svg#svgFontName') format('svg');
}
body {
  font: serif;
}
html.fonts-loaded body {
  font-family: Prociono, serif; letter-spacing: 0.04em; word-spacing: -0.05em;
  // letter-spacing: 0.2px;
}

body {
  background: #fdfdfd; color: #292929;
  font-size: 16px;
	position: relative;
	a {
	  text-decoration: none;
	  color: #20a;
	}
	a:hover { text-decoration: underline; }
	a:visited { /* color: #00f !important; */ }
	a.plain:hover { text-decoration: none; }
}

@media (prefers-color-scheme: dark) {
	body {
		background: #141419;
		color: #ccc;
		a { color: #f99; }
		p { color: #fee; }
	}
}

body#index {
	section {
	  max-width: 560px;
	  padding: 16px;
	}
	p {
	  line-height: 1.5em;
	  margin: 0 0 1em 0;
	}
	p:last-child {
	  margin-bottom: 0;
	}
	h1 {
	  margin: 0 0 16px;
	  font-size: 1.333em;
	  font-weight: 300;
	  letter-spacing: 0.02em;
	}
	h2 {
	  font-weight: 300;
	  margin-bottom: 2px;
	  // letter-spacing: 0.5px;
	}
	b {
	  font-weight: normal;
	  text-transform: capitalize;
	  padding: 0 1px;
	  margin: 0 -1px;
	}

	canvas {
	  position: fixed;
	  top: 0;
	  left: 0;
	  z-index: -1;
	}
	#weird div {
	  z-index: -1;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  position: absolute;
	  background-blend-mode: lighten;
	}

	/* ---- ---- ---*/

	section#projects {
		line-height: 1.8em;
		a {
		  margin-right: 8px;
		}
		div {
			margin-bottom: 12px;
			p {
				margin-top: 0;
				font-size: 14px;
	  		// font-weight: 100;
			}
		}
	}
}
/* ---- ---- ---*/

ul#blog-links {
	li.blog-post-link {
		display: block;  outline: 1px solid black;  padding: 24px;

		a.title { font-size: 2em; color: #57f; }
		blockquote {
			border-left: 5px solid #888;
			padding-left: 5px;
		}
		small.date {
			color: #888;
		}

	}
	list-style: none;
}

#blog-post {
  margin: 2em;
}
